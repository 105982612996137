import React, { useState, useEffect } from 'react';
import { FaArrowUp } from 'react-icons/fa';
import 'bootstrap/dist/css/bootstrap.min.css';

const BackToTopButton = () => {
    const [showButton, setShowButton] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            if (window.pageYOffset > 300) {
                setShowButton(true);
            } else {
                setShowButton(false);
            }
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const scrollToTop = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    return (
        showButton && (
            <button 
                onClick={scrollToTop} 
                className="btn btn-primary position-fixed"
                style={{ bottom: '20px', right: '20px', zIndex: 1000 }}
            >
                <FaArrowUp />
            </button>
        )
    );
};

export default BackToTopButton;
