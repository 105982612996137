import React, { useState } from "react";
import styled from "styled-components";
import Header from "../../Layout/Header";
import Header2 from "../../Layout/Header2";
import Footer from "../../Layout/Footer";
import Copy from "../../Layout/Copy";

const Activities = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedYear, setSelectedYear] = useState("");

  const Photos = [
    {
      id: 1,
      image: "Image/img11.webp",
      name: "Planting Greens",
      year: "2007",
      description: "Our students are actively greening our school by planting trees and learning about environmental stewardship."
    },
    {
      id: 6,
      image: "Image/img12.webp",
      name: "Donating Clothes and more",
      year: "2020",
      description: "Our students are giving back by donating essential items to those in need. Their compassionate efforts support and uplift our community."
    },
    {
      id: 3,
      image: "Image/img13.webp",
      name: "Planting Greens",
      year: "2023",
      description: "The trees planted today will stand as a lasting legacy of our students' dedication to preserving the environment. As these trees grow and flourish"
    },
    {
      id: 4,
      image: "Image/img14.webp",
      name: "A photo in NGO",
      year: "2020",
      description: "Our students are supporting local NGOs, contributing to meaningful community efforts and driving positive change."

    }
  ];

  // Remove any undefined or empty years
  const uniqueYears = Array.from(new Set(Photos.map(photo => photo.year))).filter(year => year);

  const filteredPhotos = Photos
    .filter(photo => {
      const matchesSearch = photo.name.toLowerCase().includes(searchTerm.toLowerCase()) || 
                            photo.description.toLowerCase().includes(searchTerm.toLowerCase());
      const matchesYear = selectedYear === "" || photo.year === selectedYear;
      return matchesSearch && matchesYear;
    })
    .sort((a, b) => {
      if (a.name.toLowerCase().includes(searchTerm.toLowerCase()) && !b.name.toLowerCase().includes(searchTerm.toLowerCase())) return -1;
      if (!a.name.toLowerCase().includes(searchTerm.toLowerCase()) && b.name.toLowerCase().includes(searchTerm.toLowerCase())) return 1;
      return 0;
    });

  return (
    <>
      <Header />
      <Header2 heading="Student Activities" />
      <Wrapper>
        <div className='container-fluid mt-5' data-aos="zoom-in">
          <div className='row d-flex align-items-center justify-content-center mb-5 mx-3'>
            <div className='col-lg-4 col-xl-3 col-md-5 Forest border d-flex align-items-center justify-content-center'>
              <div className='d-flex align-items-center border-end'>
                <div className='dropdown'>
                  <button className="btn dropdown-toggle ms-3 py-1 px-2 py-lg-2 px-lg-2 py-md-2 px-md-2" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                    {selectedYear || "All"}
                  </button>
                  <ul className="dropdown-menu dropdownbox border border-dark">
                    <li><button className="dropdown-item px-2 py-2" onClick={() => setSelectedYear("")}>All</button></li>
                    {uniqueYears.map(year => (
                      <li key={year}><button className="dropdown-item px-2 py-2" onClick={() => setSelectedYear(year)}>{year}</button></li>
                    ))}
                  </ul>
                </div>
              </div>

              <div className='search-container d-flex align-items-center justify-content-center ms-3 ms-lg-2 ms-xl-4 place col-lg-8 col-md-7'>
                <form className="" role="search" onSubmit={e => e.preventDefault()}>
                  <input
                    className="form-control border-0 me-5 me-lg-0 py-1 px-2 px-md-3 py-lg-2 px-lg-2 py-md-2 px-md-1"
                    type="search"
                    placeholder="Search Gallery Item..."
                    aria-label="Search"
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                  />
                </form>
              </div>
            </div>
          </div>

          <div className="row my-3">
            {filteredPhotos.map((elem) => {
              const { id, name, image, year, description } = elem;

              return (
                <div className="col-12 col-sm-6 col-md-6 col-lg-4 col-xxl-3 mb-4" key={id}>
                  <div className="card shadow p-3 mb-5 bg-body-tertiary rounded pointer-hover">
                    <img src={image} className="card-img-top cardimage" alt={name} />
                    <div className="card-body details">
                      <h5 className="card-title">{name}</h5>
                      <p className="card-text fw-bold">{year}</p>
                      <p className="card-text">{description}</p>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </Wrapper>
      <Footer />
      <Copy />
    </>
  );
};

export default Activities;

const Wrapper = styled.div`
  .card {
    width: 100%;
    height: 100%;
  }

  .searchinput {
    width: 100%;
    max-width: 150px;
    padding: 8px;
    height: 41px;
  }

  .Forest {
    border-radius: 10px;
  }

  .selectfield {
    width: 100%;
    max-width: 75px;
    padding: 8px;
  }

  .cardimage {
    width: 100%;
    height: 200px;
    object-fit: cover;
  }
  
  .details {
    width: 100%;
    height: 150px;
  }

  .dropdown-item:hover {
    background-color: #d8cdcd;
    border-radius: 5px;
  }

  @media (width: 540px) {
    .search-container {
      width: 220px;
    }

    .Forest {
      width: 350px;
    }
  }
  
  @media (min-width: 576px) {
    .form-control, .form-select {
      max-width: 300px;
    }
  }
`;




// import React, { useEffect, useState } from "react";
// import styled from "styled-components";
// import { Link } from "react-router-dom";
// import Header from "../../Layout/Header";
// import Header2 from "../../Layout/Header2";
// import Button from "react-bootstrap/Button";
// import Modal from "react-bootstrap/Modal";
// import axios from "axios";
// import { IoIosArrowForward } from "react-icons/io";
// import { Carousel } from "react-bootstrap";
// import Footer from "../../Layout/Footer";
// import Copy from "../../Layout/Copy";

// const Activities = () => {
//   const [searchTerm, setSearchTerm] = useState("");
//   const [filterYear, setFilterYear] = useState(null);
//   const [show, setShow] = useState(false);
//   const [selectedCard, setSelectedCard] = useState(null);

//   const handleCardClick = (card) => {
//     setSelectedCard(card);
//     handleShow();
//   };

//   const handleClose = () => setShow(false);
//   const handleShow = () => setShow(true);

//   const [cardData, setCardData] = useState([]);

//   const fetchData = async () => {
//     try {
//       const res = await axios.get(
//         `https://www.joyseniorsecondary.ac.in/api/auth/getactivity`
//       );
//       console.log(res.data);
//       setCardData(res.data);
//     } catch (error) {
//       console.log(error);
//     }
//   };

//   useEffect(() => {
//     fetchData();
//   }, []);

//   const [imgData, setImgData] = useState([]);

//   const handleModal = async (id) => {
//     try {
//       const res = await axios.get(
//         `https://www.joyseniorsecondary.ac.in/api/auth/activityBulkImagesId/${id}`
//       );
//       console.log(res.data.images);
//       setImgData(res.data.images);
//     } catch (error) {
//       console.log(error);
//     }
//   };

//   const filteredCards = cardData
//     .filter((card) =>
//       card.title.toLowerCase().includes(searchTerm.toLowerCase())
//     )
//     .filter((card) =>
//       filterYear ? card.year && card.year.toString() === filterYear : true
//     );
//   const uniqueYears = [...new Set(cardData.map((card) => card.year))];

//   return (
//     <>
//       <Header />
//       <Header2 heading="Activities" />

//       <Wrapper>
//         <div className="container mb-5 mt-5 ">
//           <div className="d-flex justify-content-center ">
//             <select
//               className="form-select mx-2"
//               aria-label="Default select example"
//               value={filterYear || ""}
//               onChange={(e) => setFilterYear(e.target.value || null)}
//             >
//               <option value="">All</option>
//               {uniqueYears.map((year) => (
//                 <option key={year} value={year}>
//                   {year}
//                 </option>
//               ))}
//             </select>

//             <input
//               value={searchTerm}
//               onChange={(e) => setSearchTerm(e.target.value)}
//               className="form-control me-2 "
//               type="search"
//               placeholder="Search"
//               aria-label="Search"
//             />
//           </div>

//           <div className="row mt-3">
//             {filteredCards.map((card) => (
//               <div key={card.id} className="col-lg-4 mt-2">
//                 <div className="card shadow p-3 bg-white rounded-4">
//                   <img
//                     src={card.cardimg}
//                     height={300}
//                     className="card-img-top"
//                     alt={`Card ${card.id}`}
//                   />

//                   <div className="card-body">
//                     <h5 className="card-title mt-2">{card.title}</h5>
//                     {/* <p className="card-text">{card.descp}</p> */}
//                     {card.title && (
//                       <Button
//                         variant="primary"
//                         onClick={() => handleCardClick(card)}
//                       >
//                         Read More
//                       </Button>
//                     )}

//                     <button
//                       type="button"
//                       className="btn4"
//                       data-bs-toggle="modal"
//                       data-bs-target="#staticBackdrop"
//                       onClick={() => handleModal(card.id)}
//                     >
//                       <IoIosArrowForward size={30} />
//                     </button>

//                     <div
//                       className="modal fade"
//                       id="staticBackdrop"
//                       data-bs-backdrop="static"
//                       data-bs-keyboard="false"
//                       tabindex="-1"
//                       aria-labelledby="staticBackdropLabel"
//                       aria-hidden="true"
//                     >
//                       <div className="modal-dialog modal-dialog-centered">
//                         <div className="modal-content">
//                           <div className="modal-header">
//                             <h5
//                               className="modal-title"
//                               id="staticBackdropLabel"
//                             >
//                               Activities Modal
//                             </h5>
//                             <button
//                               type="button"
//                               className="btn-close"
//                               data-bs-dismiss="modal"
//                               aria-label="Close"
//                             ></button>
//                           </div>
//                           <div className="modal-body">
//                             <Carousel>
//                               {imgData.map((item) => (
//                                 <Carousel.Item>
//                                   <div className="d-flex justify-content-center align-items-center flex-column">
//                                     <img
//                                       className="d-block w-100"
//                                       src={item.bulkimages}
//                                       alt="event images"
//                                     />
//                                     {/* <button className="btn btn-danger" onClick={()=>deleteImages(item.id)}>
//                                     Delete
//                                   </button> */}
//                                   </div>
//                                 </Carousel.Item>
//                               ))}
//                             </Carousel>
//                           </div>
//                           {/* <div className="modal-footer">
//                             <button
//                               type="button"
//                               className="btn btn-secondary"
//                               data-bs-dismiss="modal"
//                             >
//                               Close
//                             </button>
//                             <button type="button" className="btn btn-primary">
//                               Understood
//                             </button>
//                           </div> */}
//                         </div>
//                       </div>
//                     </div>
//                   </div>
//                 </div>
//               </div>
//             ))}
//           </div>

//           <Modal
//             show={show}
//             onHide={handleClose}
//             size="lg"
//             aria-labelledby="contained-modal-title-vcenter"
//             centered
//           >
//             <Modal.Header closeButton style={{ backgroundColor: "#4d4dff" }}>
//               <Modal.Title
//                 id="contained-modal-title-vcenter"
//                 style={{ color: "white", textAlign: "center" }}
//               >
//                 {selectedCard ? selectedCard.title : "Default Title"}
//               </Modal.Title>
//             </Modal.Header>
//             <Modal.Body style={{ backgroundColor: "#4d4dff", color: "white" }}>
//               {selectedCard ? selectedCard.descp : "Default Description"}
//             </Modal.Body>
//           </Modal>
//         </div>
//       </Wrapper>
//       <Footer />
//       <Copy />
//     </>
//   );
// };

// export default Activities;
// const Wrapper = styled.div`
//   .card {
//     width: 100%;
//     height: 100%;
//     @media screen and (max-width: 768px) {
//       width: 100%;
//       height: 100%;
//     }
//   }
//   .form-control {
//     width: 25%;

//     @media screen and (max-width: 768px) {
//       width: 100%;
//     }
//   }
//   .form-select {
//     width: 10%;

//     @media screen and (max-width: 768px) {
//       width: 100%;
//     }
//   }

//   .btn4 {
//     margin-left: 8rem;
//     border: none;
//     padding: 0.5rem;
//     border-radius: 50%;
//     color: white;
//     background-color: #929fba;
//   }
// `;
