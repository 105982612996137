import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
// import pdf from '../all imgs/Fee-instructions-2023-24-1.pdf'
import pdf2 from '../all imgs/Fee-Structure-2023-24-b9.pdf'
import logo from "../images/DGlogo.png"
import BackToTopButton from '../components/BackToTopButton.jsx';

function Footer() {
  const schoolName =
    "Doaguru School";
  const addressLine1 =
    "Plot No. B, kF Complex No. 6/18,";
  const addressLine2 = "Wright Town";
  const city = "Jabalpur";
  const postalCode = "482001";
  const state = "M.P.";
  const emailAddress =
    "doaguruschool@gmail.com";
  const contact1 = "+91 9996652379";
  const contact2 = "+91 6565482514";
  const contact3 = "0761-2421738";

  const googleMapsUrl = `https://www.google.com/maps?q=${schoolName},${addressLine1},${addressLine2},${city}-${postalCode},${state}`;

  const handleTop = () => {
    window.scrollTo(0, 0);
  };
  return (
    <>
      <StyledDiv>
        <div className="Footer mt-2 ">
          <div className="container-fluid mx-lg-0">
            <div className="row">
              <div className="d-md-flex d-lg-flex justify-content-around">
                <div className="d-none d-lg-block d-lg-block d-xxl-block">
                  <div>
                    <img src={logo} alt="" className="footlogo"/>
                  </div>
                  <div className="col-md-12 col-lg-12 col-12 ft-0 social">
                    <a
                      className="btn btn-floating m-1 px-md-2"
                      href="https://www.youtube.com/@doaguru.infosystems"
                      role="button">
                      <i class="bi bi-youtube icon text-danger"></i>
                    </a>
                    <a
                      className="btn btn-floating m-1 px-md-2"
                      href="https://www.facebook.com/Digitalmarketingandwebsitedesigncompany/"
                      role="button">
                      <i class="bi bi-facebook icon text-primary"></i>
                    </a>
                    <a
                      className="btn btn-floating m-1 px-md-2"
                      href="https://www.instagram.com/doaguruinfosystems/?hl=en"
                      role="button">
                      <i className="bi bi-instagram icon insta text" style={{ color: '#cd486b' }}></i>

                    </a>
                    <a
                      className="btn btn-floating m-1 px-md-2"
                      href="https://www.linkedin.com/company/doaguru-info-sys/"
                      role="button">
                      <i class="bi bi-linkedin icon text-primary"></i>
                    </a>
                  </div>
                </div>
                <div className="include contact col-md-3 col-lg-3 col-12 ft-1">
                  <h3 className="fhead">
                    <span className="yspan">
                      [
                    </span>
                    Contact information
                    <span className="yspan">
                      ]
                    </span>
                  </h3>
                  <div className="data1 datas">
                    <a
                      href={googleMapsUrl}
                      target="_blank"
                      rel="noopener noreferrer"
                      style={{
                        textDecoration:
                          "none",
                      }}>
                      <div className="d-flex">
                        <span className="yspan">
                          &gt;
                        </span>{" "}
                        <div className="marginl">
                          <p className="text-start text-dark fw-semibold">
                            {schoolName}
                          </p>
                          <p className="text-start text-dark fw-semibold">
                            {addressLine1}
                          </p>
                          <p className="text-start text-dark fw-semibold">
                            {addressLine2}{" "}
                            {city}-
                            {postalCode}(
                            {state})
                          </p>
                        </div>
                      </div>
                    </a>

                  </div>
                  <div className="d-flex">
                    <span className="yspan">
                      &gt;
                    </span>
                    <div className="marginl">
                      <p className="text-start text-dark fw-semibold datas">
                        &nbsp;
                        <a
                          href={`tel:${contact1}`}
                          style={{
                            textDecoration:
                              "none",
                            color: "black",

                          }}>
                          {contact1}
                        </a>
                        <br />
                        <a
                          href={`tel:${contact2}`}
                          className="ml-1"
                          style={{
                            textDecoration:
                              "none",
                            color: "black",

                          }}>
                          {contact2}
                        </a>
                        <br />
                        <a
                          href={`tel:${contact3}`}
                          className="ml-1"
                          style={{
                            textDecoration:
                              "none",
                            color: "black",


                          }}>
                          {contact3}
                        </a>
                      </p>
                    </div>
                  </div>
                  <div className="d-flex">
                    <span className="yspan">
                      &gt;
                    </span>
                    <div className="marginl">
                      <p className="text-start text-dark fw-semibold datas">
                        &nbsp;
                        <a
                          href={`mailto:${emailAddress}`}
                          style={{
                            textDecoration:
                              "none",
                            color: "black",
                          }}>
                          {emailAddress}
                        </a>
                      </p>
                    </div>
                  </div>
                </div>
                <div className="include col-md-3 col-lg-3 col-12 ft-2 links">
                  <h3 className="fhead">
                    <span className="yspan">
                      [
                    </span>
                    Quick Links
                    <span className="yspan">
                      ]
                    </span>
                  </h3>
                  <ul
                    className="ps-0 "
                    style={{
                      listStyle: "none",
                    }}>
                    <li className="nav-item py-2 py-2 links">
                      <span className="yspan">
                        &gt;
                      </span>
                      &nbsp;
                      <Link
                        className="text-decoration-none text-dark fw-semibold ml-2"
                        to="/school-annual-calendar"
                        onClick={handleTop}
                      >

                        Annual School
                        Calender
                      </Link>
                    </li>
                    <li className="nav-item py-2 links">
                      <span className="yspan">
                        &gt;
                      </span>
                      &nbsp;
                      <Link
                        className="text-decoration-none text-dark fw-semibold ml-2"
                        to="/our-magazine"
                        onClick={handleTop}
                      >
                        Our School
                        Magazine
                      </Link>
                    </li>
                    <li className="nav-item py-2 links">
                      <span className="yspan">
                        &gt;
                      </span>
                      &nbsp;
                      <Link
                        className="text-decoration-none text-dark fw-semibold ml-2"
                        to="/birthday-corner"
                        onClick={handleTop}
                      >

                        Birthday Corner
                      </Link>
                    </li>
                  </ul>
                </div>
                <div className="include col-md-3 col-lg-3 col-12 ft-3 links">
                  <h3 className="fhead">
                    <span className="yspan">
                      [
                    </span>
                    Other Info
                    <span className="yspan">
                      ]
                    </span>
                  </h3>
                  <ul
                    className="ps-0"
                    style={{
                      listStyle: "none",
                    }}>
                    <li className="nav-item py-2">
                      <span className="yspan">
                        &gt;
                      </span>
                      &nbsp;
                      <Link
                        className="text-decoration-none text-dark fw-semibold ml-2"
                        to="/our-uniform"
                        onClick={handleTop}
                      >
                        Our Uniform
                      </Link>
                    </li>
                    <li className="nav-item py-2">
                      <span className="yspan">
                        &gt;
                      </span>
                      &nbsp;
                      <Link
                        className="text-decoration-none text-dark fw-semibold ml-2"
                        to="/admin-LogIn"
                        target="_blank"
                      >
                        Admin
                      </Link>
                    </li>
                    <li className="nav-item py-2">
                      <span className="yspan">
                        &gt;
                      </span>
                      &nbsp;
                      <Link
                        className="text-decoration-none text-dark fw-semibold ml-2"
                      // to={pdf2}
                      // target="_blank"
                      >
                        Fees Structure
                      </Link>

                    </li>
                  </ul>
                </div>
              </div>
              <div className="d-md-block d-lg-none d-xl-none d-xxl-none col-md-12 col-lg-12 col-12 ft-0 social">
                <a
                  className="btn btn-floating m-1 px-md-2"
                  href="https://www.youtube.com/@doaguru.infosystems"
                  role="button">
                  <i class="bi bi-youtube icon text-danger"></i>
                </a>
                <a
                  className="btn btn-floating m-1 px-md-2"
                  href="https://www.facebook.com/Digitalmarketingandwebsitedesigncompany/"
                  role="button">
                  <i class="bi bi-facebook icon text-primary"></i>
                </a>
                <a
                  className="btn btn-floating m-1 px-md-2"
                  href="https://www.instagram.com/doaguruinfosystems/?hl=en"
                  role="button">
                  <i className="bi bi-instagram icon insta text" style={{ color: '#cd486b' }}></i>

                </a>
                <a
                  className="btn btn-floating m-1 px-md-2"
                  href="https://www.linkedin.com/company/doaguru-info-sys/"
                  role="button">
                  <i class="bi bi-linkedin icon text-primary"></i>
                </a>
              </div>
            </div>
          </div>
        </div>
          <BackToTopButton />

      </StyledDiv>
    </>
  );
}

export default Footer;
const StyledDiv = styled.div`


.footlogo {
  width: 250px;
}

  .Footer {
    background-color: #eee;
    padding: 3rem 0;
    border-top-right-radius: 1.5rem;
    border-top-left-radius: 1.5rem;
  }
  .ft-1 h3 {
    font-weight: 800;
    font-family: Georgia,
      "Times New Roman", Times, serif;
    color: black;
    letter-spacing: 3px;
    text-transform: uppercase;
    font-size: 1em;
  }
  .ft-2 h3 {
    font-weight: 800;
    font-family: Georgia,
      "Times New Roman", Times, serif;
    color: black;
    letter-spacing: 3px;
    text-transform: uppercase;
    font-size: 1em;
  }
  .ft-3 h3 {
    font-weight: 800;
    font-family: Georgia,
      "Times New Roman", Times, serif;
    color: black;
    letter-spacing: 3px;
    text-transform: uppercase;
    font-size: 1em;
  }
  .ft-0 h3 {
    font-weight: 800;
    font-family: Georgia,
      "Times New Roman", Times, serif;
    color: black;
    letter-spacing: 3px;
    text-transform: uppercase;
    font-size: 1em;
  }

  .ft-0 a i {
    color: black;
    font-size: 1.5rem;
  }
  .ft-0 a i:hover {
    color: #feab00;
  }
  .yspan {
    color: #feab00;
    font-weight: bolder;
    font-size: large;
  }

  .fhead {
    margin-bottom: 30px;
  }
  
  .social {
    text-align: center;
  }

  .links {
    margin-top: 10px;
  }

  .include {
    margin-left: 20px;
  }

  .insta{
    
    color: #cd486b;
  }

  .marginl {
    margin-left: 12px;
  }


    @media (min-width: 200px){
      
  .data1 {
    line-height: 12px;
  }

    }

    @media (min-width: 768px){

      .data1 {
    line-height: 18px;
  }

  h3 {
    width: 200px;
  }
  
  .links {
    width: 200px;
    margin-top: 0px;
  }

  .include {
    padding-left: 0px;
    padding-right: 0px;
  }

  .contact {
    width: 235px;
    
  }

    }

    @media (min-width: 992px){

      h3 {
        width: 263px;
      }
    }

    @media (min-width: 1600px){
      h3 {
        width: 320px;
      }
    }

`;