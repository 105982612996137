import React from "react";
import { IoIosArrowForward } from "react-icons/io";
import styled from "styled-components";
import { Link } from "react-router-dom";
import backgroundImage1 from "../../all imgs/our cards/mlschool.jpg";
import backgroundImage2 from "../../all imgs/our cards/mission.jpg";
import backgroundImage3 from "../../all imgs/our cards/contactus.jpg";

const HomeCard = () => {
  return (
    <StyledDiv>



      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-4 col-md-4">
            <div className="section1 border border-2">
              <div className="cd">
                {/* yellow line start */}
                <div className="d-flex justify-content-center align-items-center p-2">
                  <div className="border border-1 border-warning" style={{ width: "29rem" }}></div>
                </div>
                {/* yellow line end */}

                <div className="">
                  <span className="textIcon">
                    Our School <IoIosArrowForward />
                  </span>
                </div>

                <div className="btnbox p-3 d-md-flex d-lg-flex d-xl-block">
                  <button className="button mx-md-auto "><Link to="">Read More</Link></button>
                </div>
              </div>
              <div className="overlay">
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-4">
            <div className="section2 border border-2">
              <div className="cd">
                {/* yellow line start */}
                <div className="d-flex justify-content-center align-items-center p-2">
                  <div className="border border-1 border-warning" style={{ width: "29rem" }}></div>
                </div>
                {/* yellow line end */}

                <div className="visi">
                  <span className="textIcon">
                    Our Vision & <span className="misi px-md-4">Misssion</span> <IoIosArrowForward />
                  </span>
                </div>

                <div className="p-3 btnbox btnadv d-md-flex d-lg-flex d-xl-block">
                  <button className=" button mx-md-auto "><Link to="">Read More</Link></button>
                </div>
              </div>
              <div className="overlay">
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-4 ">
            <div className="section3 border border-2">
              <div className="cd">
                {/* yellow line start */}
                <div className="d-flex justify-content-center align-items-center p-2">
                  <div className="border border-1 border-warning" style={{ width: "29rem" }}></div>
                </div>
                {/* yellow line end */}

                <div className="">
                  <span className="textIcon">
                    Contact us <IoIosArrowForward />
                  </span>
                </div>

                <div className="p-3 btnbox d-md-flex d-lg-flex d-xl-block">
                  <button className="button mx-md-auto mx-lg-auto"><Link to="">Read More</Link></button>
                </div>
              </div>
              <div className="overlay">
              </div>
            </div>
          </div>
        </div>
      </div>
    </StyledDiv>
  );
};

export default HomeCard;
const StyledDiv = styled.div`

.btnbox {
  margin-top: 40px;
  width: auto;
}


.section1 {
    margin-top: 1rem;
    width: 100%;
    height: 285px;
    background-image: url(${backgroundImage1});
    background-size: cover; /* Ensures the image covers the element */
    background-repeat: no-repeat;
    background-position: center center;
    position: relative;
    overflow: hidden;
    border-radius: 0.5rem;
}

  .section2 {
    margin-top: 1rem;
    width: 100%;
    height: 285px;
    background-image: url(${backgroundImage2});
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    position: relative;
    overflow: hidden;
    border-radius: 0.5rem;
  }

  .section3 {
    margin-top: 1rem;
    width: 100%;
    height: 285px;
    background-image: url(${backgroundImage3});
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    position: relative;
    overflow: hidden;
    border-radius: 0.5rem;
  }

  .overlay {
    width: 100%;
    height: 100%;
    background-color: #000;
    opacity: 0.4;
    position: absolute;
    z-index: 1;

  }
  .cd{
    padding-top: 15%;
    position: absolute;
    z-index: 2;
  }
  .textIcon{
    font-family: 'Times New Roman', Times, serif;
    font-size: 25px;
    font-weight: 600;
    letter-spacing: 1px;
    color: white;
    padding-left: 1rem;
  }
  .button {
  background-color: white;
  color: black;
  font-weight: 500;
  border: none;
  padding: 12px 24px;
  border-radius: 5px;
  transition: all 0.3s ease; /* Smooth transition for hover effects */
}

.button:hover {      
  transform: scale(1.1);     
}

a{
    text-decoration: none;
    color: black;
 }

.button:hover a{
  color: blue;
}


@media (min-width: 768px){

.btnbox {
  margin-top: 80px;
  width: 230px;
}

.btnadv {
  margin-top: 36px;
}

.cd{
  padding-top: 6%;
}

.visi {
  width: 230px;
}

.textIcon{
  font-size: 30px;
}

}

@media (min-width: 992px){

  .btnbox {
  width: 310px;
  margin-left: 0px ;
}

.btnbox {
  /* margin-top: 40px; */
  /* width: auto; */
} 
}

@media (min-width: 1200px){

.button {
  /* display: none; */
}

}

`;