import React from 'react'
import Home_Awards from '../components/Student_Corner/Awards_Achievement/Home_Awards'
import Footer from '../Layout/Footer'
import Copy from '../Layout/Copy'

const AwardsAchievement = () => {
  return (
    <>
    <Home_Awards/>
    <Footer/>
    <Copy/>
    </>
  )
}

export default AwardsAchievement