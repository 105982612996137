import React from 'react'
import styled from "styled-components";

const Eadmission = () => {
    return (
        <>

            <Container>

                <div class="cont mt-5 mb-5  row ">
                    <div className='col-lg-11 col-xl-10 mx-auto'>
                        <h1 className='text-center '>School Registration</h1>

                        <div class="mb-4 mx-3 mx-md-5 mx-lg-5 mx-xl-5">
                            <h5 className='mb-5 text-center text-md-start text-lg-start text-xl-start text-xxl-start'>Doaguru School</h5>

                            <div className='important p-3'>
                                <p className=' fs-5 mb-3'>Additional documents for registration</p>

                                <p className=' mb-3'>Bring this registration form and the following documentation to the school office.</p>
                                <ul className='mx-3 '>
                                    <li>Original Birth Certificate</li>
                                    <li>Original Social Security Card</li>
                                    <li>Current Immunization Record</li>
                                    <li>Current Proof of Residence (ex: power, water or gas bill)</li>
                                </ul>
                            </div>
                        </div>

                        <form className=''>

                            <div class="form-section mx-3 mx-md-5 mx-lg-5 mx-xl-5">
                                <h4 className='mb-4'>Student Information</h4>
                                <div class="form-row mb-3 ">
                                    <div class="col-md-6 mb-3 mb-md-0">
                                        <label for="firstName fw-normal">First Name</label>
                                        <input type="text" class="form-control px-2 py-2" id="firstName" placeholder="First Name" />
                                    </div>
                                    <div class="col-md-6">
                                        <label for="lastName">Last Name</label>
                                        <input type="text" class="form-control px-2 py-2" id="lastName" placeholder="Last Name" />
                                    </div>
                                </div>

                                <div class="form-row mb-3">
                                    <div class="col-md-6 mb-3 mb-md-0">
                                        <label for="birthDate">Birth Date</label>
                                        <input type="date" class="form-control px-2 py-2" id="birthDate" />
                                    </div>
                                    <div class="col-md-6">
                                        <label for="gender">Gender</label>
                                        <select class="form-select px-2 py-2" id="gender">
                                            <option>Please Select</option>
                                            <option value="male">Male</option>
                                            <option value="female">Female</option>
                                            <option value="other">Other</option>
                                        </select>
                                    </div>
                                </div>

                                <div class="form-row mb-3">
                                    <div class="col-md-6 mb-3 mb-md-0">
                                        <label for="email">Email Address</label>
                                        <input type="email" class="form-control px-2 py-2" id="email" placeholder="example@example.com" />
                                    </div>
                                    <div class="col-md-6">
                                        <label for="phoneNumber">Phone Number</label>
                                        <input type="tel" class="form-control px-2 py-2" id="phoneNumber" placeholder="+91" />
                                    </div>
                                </div>
                                <div class="form-row mb-3">
                                    <div class="col-md-6">
                                        <label>Have you previously applied to or attended this school?</label>
                                        <div className='mt-3 d-flex-column'>
                                            <div class="form-check form-check-inline mr-5 ml-4">
                                                <input class="form-check-input" type="radio" name="previousApplication" id="previousApplicationYes" value="yes" />
                                                <label class="form-check-label" for="previousApplicationYes">Yes</label>
                                            </div>
                                            <div class="form-check form-check-inline">
                                                <input class="form-check-input" type="radio" name="previousApplication" id="previousApplicationNo" value="no" />
                                                <label class="form-check-label" for="previousApplicationNo">No</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="form-row mb-3">
                                    <div class="col-md-6">
                                        <label for="previousYear">If yes, what year?</label>
                                        <input type="text" class="form-control px-2 py-2 mt-1" id="previousYear" />
                                    </div>
                                </div>
                            </div>


                            <div class="form-section mx-3 mx-md-5 mx-lg-5 mx-xl-5">
                                <h4 className='mt-5 mx-1 mb-4'>Current Residence Information</h4>
                                <div class="form-row mb-3">
                                    <div class="col-md-6 mb-3 mb-md-0">
                                        <label for="address">Address</label>
                                        <input type="text" class="form-control px-2 py-2" id="address" placeholder="Street Address" />
                                    </div>
                                    <div class="col-md-6">
                                        <label for="address2">Address Line 2</label>
                                        <input type="text" class="form-control px-2 py-2" id="address2" placeholder="Street Address Line 2" />
                                    </div>
                                </div>

                                <div class="form-row mb-3">
                                    <div class="col-md-4 mb-3 mb-md-0">
                                        <label for="city">City</label>
                                        <input type="text" class="form-control px-2 py-2" id="city" placeholder="City" />
                                    </div>
                                    <div class="col-md-4 mb-3 mb-md-0">
                                        <label for="state">State</label>
                                        <input type="text" class="form-control px-2 py-2" id="state" placeholder="State" />
                                    </div>
                                    <div class="col-md-4">
                                        <label for="postalCode">PinCode</label>
                                        <input type="text" class="form-control px-2 py-2" id="postalCode" placeholder="PinCode" />
                                    </div>
                                </div>

                                <div class="form-row mb-3">
                                    <div class="col-md-6">
                                        <label for="homePhoneNumber">Home Phone Number</label>
                                        <input type="tel" class="form-control px-2 py-2" id="homePhoneNumber" placeholder="+91" />
                                    </div>
                                </div>
                            </div>

                        </form>


                        <div className='mx-3 mx-md-5 mx-lg-5 mx-xl-5'>
                            <h4 className='mt-5 mb-4'>Previous School</h4>
                            <form>
                                <div className='form-row '>
                                    <div class="col-md-6 mb-3">
                                        <label for="schoolName" class="form-label">School Name</label>
                                        <input type="text" class="form-control px-2 py-2" id="schoolName" />
                                    </div>

                                </div>
                                <div className='form-row'>
                                    <div class="col-md-6 mb-3">
                                        <label for="city" class="form-label">City</label>
                                        <input type="text" class="form-control py-2 px-2" id="city" />
                                    </div>
                                    <div class="mb-3 col-md-6">
                                        <label for="state" class="form-label">State</label>
                                        <select class="form-select py-2 px-2" id="state">
                                            <option>Please Select</option>
                                            <option>State 1</option>
                                            <option>State 2</option>
                                            <option>State 3</option>
                                        </select>
                                    </div>
                                </div>
                                <div className='form-row mb-3'>
                                    <div class="col-md-6 mb-3 mb-md-0">
                                        <label for="birthDate">Start Date</label>
                                        <input type="date" class="form-control px-2 py-2" id="startdate" />
                                    </div>
                                    <div class="col-md-6">
                                        <label for="birthDate">End Date</label>
                                        <input type="date" class="form-control px-2 py-2" id="enddate" />
                                    </div>
                                </div>
                                <div class="form-row mb-5 mx-1">
                                    <label for="notes" class="form-label">Notes</label>
                                    <textarea class="form-control" className='col-md-12 border rounded-2 py-2 px-2' id="notes" rows="3" placeholder="Type your message."></textarea>
                                </div>
                                <div className='submitbutton'>
                                    <button type="submit" class="mx-1 btn btn-primary px-5 py-2">Submit</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </Container>

        </>
    )
}

export default Eadmission;
const Container = styled.div`



.submitbutton {
    /* margin-left: 200px; */
}

.cont {
    background-color: #ffffff;
}

        .container {
            max-width: 800px;
            background-color: white;
            padding: 20px;
            border-radius: 10px;
            box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
        }
        h1 {
            text-align: center;
            margin-bottom: 30px;
            font-family: Arial, sans-serif;
            color: #333;
        }
        h5 {
            font-family: Arial, sans-serif;
            color: #555;
            font-size: 23px;
        }
        label {
            font-weight: 500;
        }
        .form-section {
            margin-bottom: 30px;
        }

        .important {
            background-color: #f9f6e2;
        }


@media (min-width: 430px){

}

@media (min-width: 576px){

}

@media (min-width: 768px){

    label {
            font-weight: 700;
        }
}

@media (min-width: 992px){

}

@media (min-width: 1200px){

}

@media (min-width: 1600px){


}
`;