import React, { useState } from "react";
import styled from "styled-components";
import Header from "../Layout/Header";
import Footer from "../Layout/Footer";
import Header2 from "../Layout/Header2";
import { MdKeyboardArrowDown } from "react-icons/md";


const Noticeboard = () => {
  const [filter, setFilter] = useState("all");
  const [isDropdownOpen, setDropdownOpen] = useState(false);

  const notices = [
    {
      title: "Upcoming Mathematics Exam",
      category: "Exams",
      date: "August 30, 2024",
      content: "The Mathematics final exam will be held on September 15th. Please prepare accordingly."
    },
    {
      title: "Science Fair Announcement",
      category: "Events",
      date: "August 28, 2024",
      content: "The annual Science Fair will take place on September 20th. All students are encouraged to participate."
    },
    {
      title: "New Library Timings",
      category: "Announcements",
      date: "August 25, 2024",
      content: "The library will now be open from 8 AM to 6 PM on weekdays. Please adhere to the new timings."
    },
    {
      title: "Circular: School Uniform Guidelines",
      category: "Circulars",
      date: "August 20, 2024",
      content: "A new circular regarding the school uniform guidelines has been issued. Please ensure compliance."
    }
  ];

  const categories = ["All Categories", "Exams", "Events", "Announcements", "Circulars"];

  const handleFilterChange = (category) => {
    setFilter(category);
    setDropdownOpen(false);
  };

  const filteredNotices = notices.filter(
    (notice) => filter === "all" || notice.category === filter
  );

  return (
    <>
      <Container>
        <Header />
        <Header2 heading="Notice Board" />

        <div className="container notice-board mt-5 mb-5">
          <h1 className="text-center mb-4">School Notice Board</h1>

          <div className="category-filter mb-4 d-flex ">
            <label htmlFor="filterCategory" className="form-label mt-1 mr-md-2">
              Filter by Category:
            </label>
            <div className="custom-dropdown">
              <button
                id="filterCategory"
                className="btn custom-select-btn border"
                onClick={() => setDropdownOpen(!isDropdownOpen)}
              >
                {filter === "all" ? "All Categories" : filter}
                <MdKeyboardArrowDown className="downarrow" />

              </button>
              {isDropdownOpen && (
                <div className="custom-dropdown-menu">
                  {categories.map((category, index) => (
                    <div
                      key={index}
                      className="custom-dropdown-item"
                      onClick={() => handleFilterChange(category === "All Categories" ? "all" : category)}
                    >
                      {category}
                    </div>
                  ))}
                </div>
              )}
            </div>
          </div>

          {filteredNotices.map((notice, index) => (
            <div key={index} className="notice mb-3">
              <div className="d-flex justify-content-between align-items-center">
                <div>
                  <div className="notice-title fw-bold">
                    {notice.title}
                  </div>
                  <div className="notice-category text-muted">
                    <span className="category fw-bold">Category</span>: {notice.category}
                  </div>
                </div>
                <div className="notice-date text-muted">
                  {notice.date}
                </div>
              </div>
              <div className="notice-content mt-2">
                {notice.content}
              </div>
            </div>
          ))}
        </div>
      </Container>
      <Footer />
    </>
  );
};

export default Noticeboard;

const Container = styled.div`

  .notice-title {
    color: #862ce7;
    font-size: 20px;
  }

  .custom-dropdown {
    position: relative;
  }
  
  .custom-select-btn {
    width: 200px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .downarrow{
    margin-left: 20px;
  }

  .custom-dropdown-menu {
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 1000;
    display: block;
    width: 100%;
    padding: 0.5rem 0;
    margin: 0.125rem 0 0;
    font-size: 1rem;
    color: #212529;
    text-align: left;
    list-style: none;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid rgba(0, 0, 0, 0.15);
    border-radius: 0.25rem;
  }

  .custom-dropdown-item {
    padding: 0.25rem 1.5rem;
    cursor: pointer;
  }

  .custom-dropdown-item:hover {
    background-color: #f8f9fa;
  }

  .category-filter {
    justify-content: space-around;
  }


@media (min-width: 768px){

  .category-filter {
    justify-content: center;
  }

}

`;
