import React, { useState } from "react";
import styled from "styled-components";
import Header from "../../Layout/Header";
import Header2 from "../../Layout/Header2";
import Footer from "../../Layout/Footer";
import Copy from "../../Layout/Copy";

const Photo_Gallery = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedYear, setSelectedYear] = useState("");

  const Photos = [
    {
      id: 1,
      image: "Image/p9.webp",
      name: "Annual School Events",
      year: "2007",
      description: "Students rehearsing with passion and precision for the school’s annual event, perfecting their dance routines for a memorable performance."
    },
    {
      id: 6,
      image: "Image/p8.webp",
      name: "School Exhibitions",
      year: "2020",
      description: "Presenting student innovations, creative artworks, and science projects, highlighting the broad talents and commitment of our students."
    },
    {
      id: 3,
      image: "Image/p4.webp",
      name: "Annual School Events",
      year: "2023",
      description: "Year 2023 : The memorable highlights of our annual events capture the joy and excitement of our school community."
    },
    {
      id: 4,
      image: "Image/p5.webp",
      name: "Honoring Excellence in Teaching",
      year: "2020",
      description: "Prize : Highlighting the recognition of achievements."
    },
    {
      id: 5,
      image: "Image/p7.webp",
      name: "School Exhibitions",
      year: "2024",
      description: "Student projects, artwork, and scientific displays, demonstrating the diverse talents and hard work of our students."
    },
    {
      id: 2,
      image: "Image/p3.webp",
      name: "Annual School Events",
      year: "2022",
      description: "Year 2022 : The exciting moments from our annual events."
    },
    {
      id: 7,
      image: "Image/p1.webp",
      name: "Classroom Activities",
      description: "Take a look inside our classrooms."
    },
    {
      id: 8,
      image: "Image/p2.webp",
      name: "Classroom Activities",
      description: "Students actively participating in their studies, collaborative projects, and everyday classroom experiences."
    },
    {
      id: 9,
      image: "Image/p6.webp",
      name: "School Exhibitions",
      description: "Explore the creativity and innovation displayed at our school exhibitions."
    },
  ];

  // Remove any undefined or empty years
  const uniqueYears = Array.from(new Set(Photos.map(photo => photo.year))).filter(year => year);

  const filteredPhotos = Photos
    .filter(photo => {
      const matchesSearch = photo.name.toLowerCase().includes(searchTerm.toLowerCase()) || 
                            photo.description.toLowerCase().includes(searchTerm.toLowerCase());
      const matchesYear = selectedYear === "" || photo.year === selectedYear;
      return matchesSearch && matchesYear;
    })
    .sort((a, b) => {
      if (a.name.toLowerCase().includes(searchTerm.toLowerCase()) && !b.name.toLowerCase().includes(searchTerm.toLowerCase())) return -1;
      if (!a.name.toLowerCase().includes(searchTerm.toLowerCase()) && b.name.toLowerCase().includes(searchTerm.toLowerCase())) return 1;
      return 0;
    });

  return (
    <>
      <Header />
      <Header2 heading="Photo Gallery" />
      <Wrapper>
        <div className='container-fluid mt-5' data-aos="zoom-in">
          <div className='row d-flex align-items-center justify-content-center mb-5 mx-3'>
            <div className='col-lg-4 col-xl-3 col-md-5 Forest border d-flex align-items-center justify-content-center'>
              <div className='d-flex align-items-center border-end'>
                <div className='dropdown'>
                  <button className="btn dropdown-toggle ms-3 py-1 px-2 py-lg-2 px-lg-2 py-md-2 px-md-2" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                    {selectedYear || "All"}
                  </button>
                  <ul className="dropdown-menu dropdownbox border border-dark">
                    <li><button className="dropdown-item px-2 py-2" onClick={() => setSelectedYear("")}>All</button></li>
                    {uniqueYears.map(year => (
                      <li key={year}><button className="dropdown-item px-2 py-2" onClick={() => setSelectedYear(year)}>{year}</button></li>
                    ))}
                  </ul>
                </div>
              </div>

              <div className='search-container d-flex align-items-center justify-content-center ms-3 ms-lg-2 ms-xl-4 place col-lg-8 col-md-7'>
                <form className="" role="search" onSubmit={e => e.preventDefault()}>
                  <input
                    className="form-control border-0 me-5 me-lg-0 py-1 px-2 px-md-3 py-lg-2 px-lg-2 py-md-2 px-md-1"
                    type="search"
                    placeholder="Search Gallery Item..."
                    aria-label="Search"
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                  />
                </form>
              </div>
            </div>
          </div>

          <div className="row my-3">
            {filteredPhotos.map((elem) => {
              const { id, name, image, year, description } = elem;

              return (
                <div className="col-12 col-sm-6 col-md-6 col-lg-4 col-xxl-3 mb-4" key={id}>
                  <div className="card shadow p-3 mb-5 bg-body-tertiary rounded pointer-hover">
                    <img src={image} className="card-img-top cardimage" alt={name} />
                    <div className="card-body details">
                      <h5 className="card-title">{name}</h5>
                      <p className="card-text fw-bold">{year}</p>
                      <p className="card-text">{description}</p>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </Wrapper>
      <Footer />
      <Copy />
    </>
  );
};

export default Photo_Gallery;

const Wrapper = styled.div`
  .card {
    width: 100%;
    height: 100%;
  }

  .searchinput {
    width: 100%;
    max-width: 150px;
    padding: 8px;
    height: 41px;
  }

  .Forest {
    border-radius: 10px;
  }

  .selectfield {
    width: 100%;
    max-width: 75px;
    padding: 8px;
  }

  .cardimage {
    width: 100%;
    height: 200px;
    object-fit: cover;
  }
  
  .details {
    width: 100%;
    height: 150px;
  }

  .dropdown-item:hover {
    background-color: #d8cdcd;
    border-radius: 5px;
  }

  @media (width: 540px) {
    .search-container {
      width: 220px;
    }

    .Forest {
      width: 350px;
    }
  }
  
  @media (min-width: 576px) {
    .form-control, .form-select {
      max-width: 300px;
    }
  }
`;
